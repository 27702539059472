<script lang="ts">
  import { browser } from "$app/environment";
  import { page } from "$app/stores";
  import { setLocale } from "$i18n/i18n-svelte";
  import { loadLocaleAsync } from "$i18n/i18n-util.async";
  import "$lib/assets/global.css";
  import authStore from "$lib/stores/authStore";
  import { langaugeStore } from "$lib/stores/language";
  import posthog from "posthog-js";
  import { onDestroy, onMount } from "svelte";

  let profile;
  let unsubscribe;

  onMount(() => {
    unsubscribe = authStore.subscribe(async ({ profile: p }) => {
      profile = p;

      if (profile) {
        localStorage.setItem("language", profile?.language);
        langaugeStore.set(profile?.language);

        await loadLocaleAsync(profile?.language);
        setLocale(profile?.language);
      } else {
        const language = localStorage.getItem("language") || "English";
        langaugeStore.set(language);

        await loadLocaleAsync(language);
        setLocale(language);
      }
    });
  });
  $: unsubscribe && onDestroy(unsubscribe);
  $: $page.url.pathname, browser && posthog.capture("$pageview");

  // Identify with PostHog
  $: $authStore.profile,
    $authStore.profile &&
      posthog.identify($authStore.profile.email, {
        email: $authStore.profile.email,
        tier: $authStore.profile.tier,
        subscriptionStatus: $authStore.profile.subscriptionData?.status || "",
        subscriptionCycle: $authStore.profile.subscriptionData?.cycle || "",
      });
</script>

<main data-sveltekit-preload-data>
  <slot />
</main>
