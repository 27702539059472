import posthog from "posthog-js";
import { browser } from "$app/environment";
import { env } from "$env/dynamic/public";

export const prerender = "auto";
export const load = async () => {
  if (browser) {
    posthog.init(env.PUBLIC_POSTHOG_PROJECT, { api_host: "https://app.posthog.com" });
  }
  return;
};
